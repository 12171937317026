import Header from "../Components/Home/Header";
import Main from "../Components/Home/Main";
import Collections from "../Components/Home/Collections";
import Building from "../Components/Home/WeAreBuilding";
import Roadmap from "../Components/Home/Roadmap";
import Footer from "../Components/Home/Footer";

function Home() {
    return(
        <main>
            <Header/>
            <Main />
            <Collections />
            <Building />
            <Roadmap />
            <Footer />
        </main>
    )
}

export default Home;