function Building() {
    return (
        <main className="bg-[#56cdf0] pt-24 md:pt-32" id="Building">
            <h1 className="text-5xl md:text-6xl text-white font-bold text-center title">What Are we Building?</h1>

            <div className="pl-12 md:pl-52 text-left pt-10">
                <h1 className="text-white font-bold text-4xl title">Token $TCAT</h1>
                <h6 className="text-white text-base title pt-1">we deploy a token on trx inscription with the ticker $TCAT, <br/> total supply 21,000.000</h6>
            </div>

            <div className="pr-12 md:pr-52 text-right pt-10">
                <h1 className="text-white font-bold text-4xl title">TronCat Collections</h1>
                <h6 className="text-white text-base title pt-1">we create a collection of nfts in trx inscription, <br/>the theme we use is cat miaw! with a total supply of 1111 NFTs</h6>
            </div>

            <div className="pl-12 md:pl-52 text-left pt-10">
                <h1 className="text-white font-bold text-4xl title">TronCat BOT</h1>
                <h6 className="text-white text-base title pt-1">we create a bot on telegram and discord, <br /> a token sales bot and a collection</h6>
            </div>
        </main>
    )
}


export default Building;