import Image1 from "../../assets/img/001.png"
import Image2 from "../../assets/img/002.png"
import Image3 from "../../assets/img/003.png"
import Image4 from "../../assets/img/004.png"
import Image5 from "../../assets/img/005.png"
import Image6 from "../../assets/img/006.png"
import Image7 from "../../assets/img/007.png"
import Image8 from "../../assets/img/008.png"

function Collections() {
    return(
        <main className="bg-[#56cdf0] pt-24 md:pt-36" >
            <h1 className="text-6xl md:text-8xl title text-white font-bold text-center" id="Collections">Our Collections</h1>

            <div className="grid pt-8">
                <div className="mx-auto md:flex ">
                    <img src={Image1} className="w-56 h-56s" alt="Tron Cat"/>
                    <img src={Image7} className="w-56 h-56" alt="Tron Cat"/>
                    <img src={Image3} className="w-56 h-56" alt="Tron Cat"/>
                    <img src={Image4} className="w-56 h-56" alt="Tron Cat"/>
                </div>
                <div className="mx-auto md:flex hidden">
                    <img src={Image5} className="w-56 h-56s" alt="Tron Cat"/>
                    <img src={Image6} className="w-56 h-56" alt="Tron Cat"/>
                    <img src={Image2} className="w-56 h-56" alt="Tron Cat"/>
                    <img src={Image8} className="w-56 h-56" alt="Tron Cat"/>
                </div>
            </div>
        </main>
    )
}

export default Collections;