import { useState, useEffect } from "react";
import Navbar from "../../Components/Home/Navbar"
import TronCatData from "../../assets/json/troncat-data.json"
import Minted from "../../assets/json/mints.json"

function CheckMinted() {

    // Scroll Per Minted
    const NFTsPerPage = 66;
    const [currentPage, setCurrentPage] = useState(1);
    const indexOfLastNFT = currentPage * NFTsPerPage;
    const indexOfFirstNFT = indexOfLastNFT - NFTsPerPage;
    const [filteredTronCatData, setFilteredTronCatData] = useState(TronCatData);
    const currentNFTs = filteredTronCatData.slice(indexOfFirstNFT, indexOfLastNFT);


    const totalPages = Math.ceil(filteredTronCatData.length / NFTsPerPage);

    const handlePageChange = (newPage) => {
      setCurrentPage(newPage);
    };

   // Event handler for take change onchange
    const handleInputChange = (event) => {
      const inputValue = event.target.value;

      if (inputValue.trim() !== "") {

        const getTroncatIDMinted = Minted
        .filter(minted => minted.minter === event.target.value)
        .map(minted => minted.troncat_id);

        const filteredTronCatData = TronCatData.filter(troncat => getTroncatIDMinted.includes(troncat.troncat_id));

        setFilteredTronCatData(filteredTronCatData)
      } else {
        setFilteredTronCatData(TronCatData);
      }
    };

    return (
        <main className="bg-[#56cdf0] min-h-screen">
          {/* Navbar */}
          <Navbar />

          <div className="md:px-28">
            <h1 className="title text-white text-center text-6xl pt-7">TronCat Checker</h1>

            <div className="md:grid md:grid-cols-3 pt-2">
              <div className="title text-white text-center pt-5 text-2xl">
                <h1>Supply :</h1>  
                <p>1111 / 1111</p>
                <p>SoldOut! do not minted.</p>
              </div>

              <div>
                <h1 className="text-center text-white text-3xl title pt-7"> Input Your Address</h1>
                <input
                  className="text-center justify-center ml-28 md:ml-3 w-48 md:w-96 h-8 rounded-xl mt-2 text-[#56cdf0]"
                  name="inputAddress"
                  onChange={handleInputChange}
                ></input>
              </div>

              <div className="title text-white text-center pt-5 text-2xl">
                <h1>Total Your Minted</h1>  
                <p>{filteredTronCatData.length}</p>
              </div>
            </div>
            
            
            <div className="grid grid-cols-4 md:grid-cols-11 pt-4 mx-3 md:mx-0">
              {currentNFTs.map((troncat) => (
                <div className="pt-5" key={troncat.troncat_id}>
                  <a href={`https://ipfs.io/ipfs/QmUH7VJZ7NMT45WC5jKrqv7J9HGQSSzKpgpKSvsfNTaJa5/${troncat.troncat_id}.png`}>
                    <img
                      src={troncat.base64_data}
                      className="w-28 px-1"
                      style={{ imageRendering: "pixelated" }}
                    />
                  </a>
                  
                  <h1 className="text-center title text-white">
                    {troncat.troncat_id}
                  </h1>
                </div>
              ))}
            </div>
            <div className="text-center pt-10 pb-10">
              {Array.from({ length: totalPages }, (_, index) => (
                <button key={index + 1} onClick={() => handlePageChange(index + 1)} className={`mx-1 px-4 py-2 my-2 rounded ${currentPage === index + 1 ? 'bg-gray-700 text-white' : 'bg-gray-300 text-gray-700'}`}>
                  {index + 1}
                </button>
              ))}
            </div>
          </div>
          
        </main>
      )
}

export default CheckMinted;