import { useState } from "react";

function Main() {
    const [floorPrice, setfloorPrice] = useState()
    const [Volume7Days, setVolume7Days] = useState()
    const [TotalVolume, setTotalVolume] = useState()

    async function getFloorPrice() {
        const response = await fetch('https://api.trximarkets.com/api/market/v1/trading-data/token?tick=tcat')

        const result = await response.json()

        const data = parseFloat(result.data.FloorPrice / 1000000 ).toFixed(3)
        const datavolume7days = parseFloat(result.data.Volume7Days / 1000000 ).toFixed(0)
        const datatotalvolume = parseFloat(result.data.TotalVolume / 1000000 ).toFixed(0)

        setfloorPrice(data)
        setVolume7Days(datavolume7days)
        setTotalVolume(datatotalvolume)
    }

    getFloorPrice()
    
    return (
        <main className="bg-[#56cdf0]" id="About">
           <div className="pt-20 md:grid md:grid-cols-2 md:pt-28">
                <div className="mx-12  md:mx-28 md:pt-2">
                    <h1 className="title text-white font-bold text-6xl md:text-8xl">TronCat</h1>
                    <h6 className="title text-white text-lg md:text-2xl pt-3 md:pt-2">TronCat is a Token and NFT Collections built on the Tron Inscription {"(TRC-20)"} network</h6>
                    <h6 className="title text-white text-lg md:text-2xl pt-3 md:pt-10">We are here to help build the TRX Inscription community to be better, we hope that by our presence we can make the TRX Inscription community even better.</h6>
                </div>
                <div className="mx-12 md:mx-28 pt-10">
                    <div>
                        <h1 className="title text-white text-5xl md:text-7xl font-bold">{floorPrice} trx</h1>
                        <h6 className="title text-white md:text-2xl">Floor Price</h6>
                    </div>
                    
                    <div className="pt-4">
                        <h1 className="title text-white text-5xl md:text-7xl font-bold">{Volume7Days} trx</h1>
                        <h6 className="title text-white md:text-2xl">Volume 7 Days</h6>
                    </div>

                    <div className="pt-4">
                        <h1 className="title text-white text-5xl md:text-7xl font-bold">{TotalVolume} trx</h1>
                        <h6 className="title text-white md:text-2xl">Total Volume</h6>
                    </div>
                    
                </div>
           </div>
        </main>
    )
}

export default Main;