import HeaderMint from "../Components/Mint/HeaderMint";

function Mint() {
    return (
        <div>
            <HeaderMint />
        </div>
    )
}

export default Mint;