import { useState } from "react"

function Navbar() {
    const [isOpen, setIsOpen] = useState(false)

    return (
        <div>
            {/* Header */}
            <nav className="bg-transparent w-full shadow-transparent2 flex-col title">
                <div className="flex flex-col md:flex-row">
                    <div className={`flex justify-between items-center py-4 px-6 md:border-none ${isOpen ? 'border-b' : 'none'}`}>
                        <div>
                        <a href="/" className="title text-white text-2xl fontLogo ml-4">
                            {/* Span */}
                            <span className="text-4xl">T</span>
                            ronCat
                        </a>
                        </div>
                        <div className="items-center block md:hidden">
                        <button onClick={() => setIsOpen(!isOpen)} className="mt-2">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="white" className="w-7 h-7">
                            <path className={`${!isOpen ? 'block' : 'hidden'}`} strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
                            <path className={`${isOpen ? 'block' : 'hidden'}`} strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </button>
                        </div>
                    </div>

                    <div className={`${isOpen ? 'block' : 'hidden'} md:flex flex-col md:flex-row justify-between w-full md:items-center border md:border-none mb-2 md:mb-0`}>
                    <div className="flex flex-col md:flex-row mx-auto">
                        <a href="/#About" className="text-white block px-6 py-2 fontLoginn md:text-1xl md:px-16 ">About</a>
                        <a href="/#Collections" className="text-white block px-6 py-2 fontLoginn md:text-1xl md:px-16">Collections</a>
                        <a href="/#Building" className="text-white block px-6 py-2 fontLoginn md:text-1xl md:px-16">What are we building</a>
                        <a href="/#Roadmap" className={`text-white block px-6 py-2 fontLoginn md:text-1xl md:px-16`}>Roadmap</a>
                    </div>
                    <div className="flex flex-col md:flex-row px-4 py-4">
                        <a href="/mint">
                            <button className={`bg-sky-400 text-white rounded-xl w-36 md:h-9 md:w-32 fontLoginn text-md hover:shadow-md mr-4`} > Mint </button>
                        </a>
                       
                    </div>
                    </div>
                </div>  
            </nav>
        </div>
    )       
}

export default Navbar;