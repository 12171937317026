function Roadmap() {
    return (
        <main className="bg-[#56cdf0] pt-24 md:pt-32 text-center" id="Roadmap">
            <h1 className="text-white text-7xl title">Roadmap</h1>
            <h6 className="text-white text-lg title">Sooonnnnn... wen wen wen mint?</h6>
        </main>
    )
}


export default Roadmap;