import Background from "../../assets/img/Background.png"
import { useState } from "react";

import Navbar from "../../Components/Home/Navbar"
function Header() {
    return (
        <main className="bg-cover h-screen bg-center" style={{backgroundImage: `url(${Background})`, backgroundSize: "cover"}}>
            {/* Navbar */}
            <Navbar />

            <div className="text-center pt-10 text-white md:pt-8">
                <h1 className="text-6xl md:text-8xl title font-bold drop-shadow-2xl">Welcome to <br/>TronCat World!</h1>
                <a href="/mint">
                    <button className="bg-sky-400 h-10 w-48 rounded-full title mt-4 md:mt-7 shadow-sky-700 hover:shadow" > Mint </button>
                </a>
            </div>
        </main>
    )
}


export default Header;