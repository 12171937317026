function Footer() {
    return (
        <main className="bg-[#56cdf0] pt-24 text-center">
            <h1 className="text-white text-lg font-bold title">2024 © @TronCat_20</h1>
            <h1 className="pt-3"></h1>
        </main>
    )
}


export default Footer;