import { BrowserRouter, Routes, Route } from "react-router-dom";
import './App.css'
import './assets/css/Font.css'

import Home from "./Pages/Home";
import Mint from "./Pages/Mint";
import CheckMinted from "./Components/Mint/CheckMint";

function App() {
    return (
      <BrowserRouter>
        <Routes>
            <Route path="/" Component={Home}/>  
            <Route path="/mint" Component={CheckMinted} />
        </Routes>
      </BrowserRouter>
  )
}

export default App;
